.logo img {
  width: 220px;
  display: block;
  margin: 6px auto;
}

.chat-message-row {
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
}

.chat-message-row .ai {
  justify-content: flex-start;
}

.chat-message-row .human {
  justify-content: flex-end;
}

.chat-message {
  white-space: pre-wrap;
  max-width: 500px;
  border-radius: 8px;
  padding: 6px 12px;
  box-shadow: 0 0 3px #d1d2da;
}

.chat-message-row.human .chat-message {
  flex-direction: row-reverse;
  background-color: #f37124;
  color: #fff;
}

.chat-message-row.ai .chat-message {
  background-color: #f9f9fe;
}
.chat-message-row.human {
  justify-content: flex-end;
}

.chat-message .loader {
  padding-bottom: 0;
}

.chat-message ul > li > p {
  margin: 0;
  margin-top: -16px;
}

/* 3rd .chat-message-row.ai  of .chat-container*/
.chat-container .chat-message-row:nth-child(2) .chat-message img {
  width: 50px;
  height: 50px;
}

.degree img {
  margin-top: -3px;
  padding-right: 3px;
}

.education-test img {
  margin-top: -3px;
  padding-right: 3px;
}
.shake-right-to-left {
  animation: shake-right-to-left 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake-right-to-left {
  10%,
  90% {
    transform: translate3d(0, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(-10px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(10px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(-10px, 0, 0);
  }
}

.field-of-study .ant-btn-block {
  width: 100%;
  text-align: left;
  margin-top: 3px;
}
.-mt-3 {
  margin-top: -3px;
}
.mr-3 {
  margin-right: 3px;
}
.system-hault, .wait-for-result {
  visibility: hidden;
}

.slick-dots {
  /* position: relative;
  top: 12px; */
}

.ant-carousel .slick-dots-bottom li button {
  background: #8c959f;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}
.ant-carousel
  .slick-dots-bottom
  li.slick-active
  button {
  background: #f37124;
}
.ant-carousel .slick-dots-bottom {
  bottom: -40px !important;
}
.ant-carousel .slick-list h3{
  border-radius: 12px;
}

.ant-carousel .slick-list img {
  width: 190px;
  height: auto;
  border-radius: 12px;
  margin: 0 auto;
}
.ant-carousel .slick-list{
  margin-left: 12px !important;
}
.ant-carousel{
  margin-top: 5rem;
}
/* At mobile screen hide the ant-layout-sider */
@media (max-width: 767px) {
  .ant-layout-sider {
    display: none;
  }
  .ant-layout-content{
    padding: 0 12px !important;
  }
  .ant-carousel .slick-list img{
   width: 60vw !important;
  }
}
.residentialstatus .ant-btn{
  margin-top: 3px;
}

input[type='text'],input[type='tel'],input[type='email'],textarea {font-size:16px !important}
